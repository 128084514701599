<template>
  <div v-if="show" class="modal-shadow" @click.self="closeModal">
    <div class="modal">
<!--      <div class="modal-close" @click="closeModal">&#10006;</div>-->
      <slot name="body">
        <div class="modal-content">
          <p>
            Заявка успешно отправлена, ожидайте обратной связи
          </p>
          <div class="modal-content__icon">
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0455 0.954505C19.4848 1.39384 19.4848 2.10616 19.0455 2.5455L8.5455 13.0455C8.10616 13.4848 7.39384 13.4848 6.9545 13.0455L0.954505 7.0455C0.515165 6.60616 0.515165 5.89385 0.954505 5.4545C1.39384 5.01517 2.10616 5.01517 2.5455 5.4545L7.75 10.659L17.4545 0.954505C17.8938 0.515165 18.6062 0.515165 19.0455 0.954505Z" fill="white"/>
            </svg>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "MessageSend",
  data: function () {
    return {

      show: false
    }
  },
  computed: {

  },
  props: ['swiperImages'],
  methods: {
    closeModal: function () {
      this.show = false
    }
  }
}
</script>

<style lang="scss">
.modal-shadow {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.post-images__slider {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 1024px) {
    max-width: 100%;
    align-items: center;
    height: 100%;
  }

}

.post-images__slider img {
  max-height: 605px;
  border-radius: 10px;
}

.modal .swiper-button-prev,
.modal .swiper-button-next {
  border-radius: 50%;
  color: #fff;
  background: #000000ab;
  width: 60px;
  height: 60px;
  font-size: 18px;
}

.modal .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after,
.modal .swiper-button-prev:after, .swiper-container-rtl .swiper-button-prev:after
{
  font-size: 16px;
}

.modal {
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  padding: 15px;
  //max-height: 805px;
  max-width: 800px;
  //height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1024px) {
    max-width: 100%;
  }

  //&-close {
  //  border-radius: 50%;
  //  color: #fff;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  position: absolute;
  //  top: 23px;
  //  right: 25px;
  //  width: 60px;
  //  height: 60px;
  //  cursor: pointer;
  //  z-index: 1001;
  //  background: #000000ab;
  //}

  &-title {
    color: #0971c7;
  }

  &-content {
    background: #FFFFFF;
    margin-bottom: 20px;
    max-width: 460px;
    width: 100%;
    padding: 36px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
    }

    &__icon {
      margin-top: 35px;
      border-radius: 50%;
      background: #0DB14B;
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  &-footer {
    &__button {
      background-color: #0971c7;
      color: #fff;
      border: none;
      text-align: center;
      padding: 8px;
      font-size: 17px;
      font-weight: 500;
      border-radius: 8px;
      min-width: 150px;
    }
  }
}
</style>
