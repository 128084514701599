<template>
  <section class=" container-margin feedback-wrapper form-new-ferm">
    <section class="block form-feedback-new container-padding block-padding-right mb-0">
      <div class="wrapper__questionary">
        <div class="questionary__title">
          <span><span>Заполните эту небольшую анкету и возможно следующий выпуск будет именно про вашу ферму!</span></span>
        </div>
        <v-form
          ref="form"
          class="questionary__form"
          v-model="valid"
          lazy-validation
        >
          <div class="questionary__field-group">
            <label class="field-group__label">Ваше имя и фамилия</label>
            <v-text-field
              name="name"
              class="field-group__input "
              placeholder="Василий Иванович"
              v-model="letterName"
              :rules="nameRules"
              required
            />
          </div>
<!--          succsess-field-->
<!--          error-field-->
          <div class="questionary__field-group">
            <label class="field-group__label">Название вашей фермы</label>
            <v-text-field
              name="farm-name"
              class="field-group__input "
              placeholder="Молочная ферма «Фермерская ферма»"
              v-model="companyName"
              :rules="companyRules"
              required
            />
<!--            <div class="error-info">Поле обязательно для заполнения</div>-->
          </div>
          <div class="questionary__field-group-two-field">
            <div class="questionary__field-group">
              <label class="field-group__label">Адрес фермы</label>
              <v-text-field
                name="farm-address"
                class="field-group__input"
                placeholder="Московская обл, Раменский р-н, д. Антоново"
                v-model="addressFarm"
                :rules="companyRules"
                required
              />
            </div>
            <div class="questionary__field-group">
              <label class="field-group__label">Телефон для связи</label>
              <v-text-field
                name="phone"
                class="field-group__input"
                placeholder="+7 (___)"
                v-model="letterPhone"
                :rules="phoneRules"
                required
                v-mask="['+7 (###) ###-##-##']"
              />
            </div>
          </div>
          <div class="questionary__field-group">
            <label class="field-group__label">Расскажите о ферме</label>
            <div class="field-group__warning-info">Краткая история вашей фермы, как появилась идея, с какими трудностями столкнулись, чему научились</div>
            <v-textarea
              name="farm-story"
              class="field-group__textarea"
              placeholder="Например: Мы никогда не думали, что станем фермерами, но в 2011 году мы с мужем поняли, что устали от шумного города и хотим больше и больше времени проводить за городом, на своем участке. Когда спустя полгода у нас родилась дочка с аллергией, мы поняли, что просто жить за городом мало - нужно производить и свою продукцию...."
              v-model="aboutFarmMessage"
              :rules="messageRules"
              auto-grow
              rows="1"
              required
              >
            </v-textarea>
          </div>
          <div class="questionary__field-group">
            <label class="field-group__label">Что производит ваша ферма</label>
            <select v-model="productTypeSelected" name="farm-product" class="field-group__select">
              <option selected disabled>Выберите</option>
              <option>Мясо и мясные продукты</option>
              <option>Сыры</option>
              <option>Молочные продукты</option>
              <option>Рыба и морепродукты</option>
              <option>Птица и яйца</option>
              <option>Хлеб и выпечка</option>
              <option>Овощи и фрукты</option>
              <option>Ягоды</option>
              <option>Мёд</option>
              <option>Агротуризм</option>
              <option>Саженцы и рассада</option>
              <option>Другое</option>
<!--              <option v-for="type in getCategories.data.results" :key="type.id">-->
<!--                {{ type.name }}-->
<!--              </option>-->
            </select>
          </div>
          <div class="questionary__field-group-two-field">
            <div class="field-group__label padding-label">А теперь, расскажите о себе</div>
            <div class="questionary__field-group">
              <label class="field-group__label-small">Как вы пришли в фермерство?</label>
              <v-textarea
                name="farm-way"
                class="field-group__textarea"
                placeholder="Например: Я окончила французскую школу, потом РГГУ, факультет мировой экономики. Работала в фармацевтической компании."
                v-model="aboutYouMessage"
                :rules="messageRules"
                auto-grow
                rows="1"
                required
              >
              </v-textarea>
            </div>
            <div class="questionary__field-group">
              <label class="field-group__label-small">Почему именно это направление?</label>
              <v-textarea
                name="farm-why"
                class="field-group__textarea"
                placeholder="Например: Я всегда говорю, что меня нашли на капустной грядке - с детства проводили все лето и каникулы у бабушки с дедушкой, которые активно привлекали нас к работе с овощами. Поэтому когда было ..."
                v-model="vectorMessage"
                :rules="messageRules"
                auto-grow
                rows="1"
                required
              >
              </v-textarea>

            </div>
          </div>
          <div class="questionary__field-group">
            <label class="field-group__label">Покажите вашу ферму в небольшом ролике и загрузите на любой удобный видео-хостинг (YouTube, RuTube, Vimeo) <span>(необязательно)</span></label>
            <v-text-field
              name="farm-video"
              class="field-group__input"
              placeholder="Ссылка на ролик"
              v-model="linkVideo"
            />
          </div>
          <div class="questionary__field-group">
            <label class="field-group__label">Ссылка на соц. сети или сайт </label>
            <v-text-field
              name="farm-photo"
              class="field-group__input"
              placeholder="Ссылка на облако"
              v-model="linkSN"
              :rules="companyRules"
              required
            />
          </div>
          <vue-recaptcha
            class="recaptch-block"
            ref="recaptcha"
            :sitekey="sitekey"
            @verify="verify"
            @expired="onCaptchaExpired"
          />
          <button @click="createFeedback" type="button" class="questionary__button">Отправить заявку</button>
          <div class="linkbox"><a href="/personal" target="_blank" class="questionary__agreement">Нажимая на кнопку вы даете согласие на обработку ваших персональных данных</a></div>
        </v-form>
      </div>
    </section>
    <message-send ref="messageSend"></message-send>
<!--    <div class="modal-success">-->
<!--      <div class="modal-success__title">Заявка успешно отправлена, ожидайте обратной связи</div>-->
<!--      <img src="../img/icon-success.svg" />-->
<!--    </div>-->
    <!----------->
    <!----------->
    <!----------->
    <!----------->
    <!----------->

  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mask } from 'vue-the-mask'
import VueRecaptcha from 'vue-recaptcha'
import MessageSend from '@/components/messageSend.vue'

export default {
  name: 'Form',
  directives: { mask },
  data() {
    return {
      dialog: false,
      valid: false,
      writeUs: '',
      productTypeSelected: '',
      nameRules: [
        v => !!v || this.$t('requiredField'),
      ],
      companyRules: [
        v => !!v || this.$t('requiredField'),
      ],
      phoneRules: [
        v => !!v || this.$t('requiredField'),
        v => v.length === 18 || this.$t('requiredFieldPhone'),
      ],
      messageRules: [
        v => !!v || this.$t('requiredField'),
      ],
      sitekey: '6LdmodwbAAAAAF5bYY5twSize5qt8zNOGHZG7Zgx',
      recaptchaToken: '',
    }
  },
  components: {VueRecaptcha, MessageSend},
  computed: {
    ...mapState(['appWidth']),
    ...mapState('form', ['yourMessageOne', 'yourMessageTwo', 'farmMessage', 'phone', 'company', 'addressOfFarm', 'name', 'linkToVideo', 'linkToSN', 'yourMessage',]),
    ...mapState('posts', ['posts']),
    ...mapState('category', ['category']),
    getCategories() {
      return this.category;
    },

    letterName: {
      get: function () {
        return this.name
      },
      set: function (value) {
        this.setName(value)
      }
    },
    companyName: {
      get: function () {
        return this.company
      },
      set: function (value) {
        this.setCompany(value)
      }
    },
    letterPhone: {
      get: function () {
        return this.phone
      },
      set: function (value) {
        this.setPhone(value)
      }
    },
    productFarm: {
      get: function () {
        return this.productOfFarm
      },
      set: function (value) {
        this.setProductFarm(value)
      }
    },
    addressFarm: {
      get: function () {
        return this.addressOfFarm
      },
      set: function (value) {
        this.setAddress(value)
      }
    },
    linkVideo: {
      get: function () {
        return this.linkToVideo
      },
      set: function (value) {
        this.setLinkVideo(value)
      }
    },
    linkSN: {
      get: function () {
        return this.linkToSN
      },
      set: function (value) {
        this.setLinkSN(value)
      }
    },
    aboutFarmMessage: {
      get: function () {
        return this.farmMessage
      },
      set: function (value) {
        this.setAboutFarm(value)
      }
    },
    aboutYouMessage: {
      get: function () {
        return this.yourMessageOne
      },
      set: function (value) {
        this.setYouMessage(value)
      }
    },
    vectorMessage: {
      get: function () {
        return this.yourMessageTwo
      },
      set: function (value) {
        this.setVectorMessage(value)
      }
    },

    // letterMessage: {
    //   get: function () {
    //     return this.message
    //   },
    //   set: function (value) {
    //     this.setMessage(value)
    //   }
    // }
  },

  methods: {
    ...mapActions(['sendFeedback']),
    ...mapActions('category', ['getPostsTypes']),
    ...mapMutations(['setAlert']),
    ...mapActions('form', ['setName', 'setProductFarm', 'setCompany', 'setAddress', 'setLinkVideo', 'setPhone', 'setYouMessage', 'setAboutFarm', 'setAboutYouMessage', 'setVectorMessage', 'setLinkSN']),

    goTo() {
      this.$router.push(`/personal`)
    },

    async verify(recaptchaToken) {
      this.recaptchaToken = recaptchaToken
    },

    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },

    async createFeedback() {
      await this.$refs.form.validate()
      if (this.valid) {
        // this.setAlert({ message: this.$t('УСПЕХ БРАТ'), isAlert: true , color: 'success'})
        if (!this.recaptchaToken) {
          this.setAlert({ message: this.$t('errorCaptchaMessage'), isAlert: true, color: 'red' })
        } else {
          // const res = await this.sendFeedback({name: this.letterName, phone: this.letterPhone, message: this.letterMessage, recaptchaToken: this.recaptchaToken})


          let message = ' \n '
            + 'Имя: '+  this.letterName
            + '\n'
            + 'Телефон: ' + this.letterPhone
            + '\n'
            + 'Компания: ' + this.companyName
            + '\n'
            + 'Адрес фермы: ' + this.addressFarm
            + '\n'
            + 'Продукт фермы: ' + this.productTypeSelected
            + '\n'
            + 'Ссылка на видео: ' + this.linkVideo
            + '\n'
            + 'Соц. сеть: ' + this.linkSN
            + '\n'
            + 'О ферме: ' + this.aboutFarmMessage
            + '\n'
            + 'О отправителе: ' + this.aboutYouMessage
            + '\n'
            + 'Направление: ' + this.vectorMessage;

          const res = await this.sendFeedback({name:this.letterName,phone: this.letterPhone, message: message})


          // console.log(res);

          if (res.status === 200) {
            // this.setAlert({ message: this.$t('feedbackMessage'), isAlert: true , color: 'success'})
            // setTimeout(() => this.setAlert({ isAlert: false }), 3000)
            setTimeout(() =>  this.$refs.messageSend.show = true, 1000);
            setTimeout(() =>  this.$refs.messageSend.show = false, 5000);

            this.letterName = ''
            this.letterPhone = ''
            this.letterMessage = ''
            this.companyName = ''
            this.addressFarm = ''
            this.linkVideo = ''
            this.aboutFarmMessage = ''
            this.aboutYouMessage = ''
            this.vectorMessage = ''

            this.$refs.recaptcha.reset();
            await this.$refs.form.resetValidation()
          }
        }
      }
    },
  },


};
</script>

<style lang="scss">
@import "src/assets/styles/variables";

@media (max-width: 575px) {
  .recaptch-block {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
}

.form-new-ferm .v-input__control .v-messages {
  display: none !important;
}

.form-new-ferm .theme--light.v-text-field>.v-input__control>.v-input__slot:before,
.form-new-ferm .v-text-field>.v-input__control>.v-input__slot:after{
  display: none !important;
}


.form-new-ferm .v-input__control .v-input__slot:before,
.form-new-ferm .v-input__control .v-input__slot:after {
  display: none !important;
}


</style>
